<template>
  <div class="billboard--content constrain-content--smaller">
    <div class="logo-wrapper">
      <logo />
    </div>
    <p class="intro">
      Fonden har upprättats för att hedra minnet av 20-åriga Eric Torell, som
      tragiskt blev ihjälskjuten av polisen för tre år sedan.
    </p>
    <p>
      Fondens avsikt är att premiera och stötta personer och organisationer vars
      idéer skapar trygghet och glädje i vardagen för personer med intellektuell
      funktionsnedsättning.
    </p>
    <p>
        <a @click.prevent="CopyToClipboard('0708448805')" class="highlight" content="Swishnumret är kopierat" v-tippy="TippyOptions">
            <span class="mask">
                <div class="highlight-container">
                <span class="highlight-title1 title">Swisha din gåva till 0708-448805</span>
                <span class="highlight-title2 title">Swisha din gåva till 0708-448805</span>
                </div>
            </span>
        </a>
    </p>
    <p class="signature">
      <span class="small">Katarina, Erics mamma</span>
    </p>
  </div>
</template>
<script>
import TooltipMixin from '@/mixins/tooltip'
export default {
  mixins: [TooltipMixin],
  components: {
    Logo: () => import("@/components/ericfonden-logo"),
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss">
.billboard--content {
  .logo-wrapper {
    margin-bottom: 4rem;
  }
}
</style>
