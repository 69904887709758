<template>
  <div>
    <full-page ref="fullpage" class="home-page" :options="FullPageOptions">
      <section class="active billboard  "><billboard /></section>
      <section class="book"><book /></section>
      <section class="donate"><donate /></section>
      <section class="about"><about /></section>
    </full-page>
  </div>
</template>

<script>
import Billboard from "@/router/content-blocks/billboard";
import Book from "@/router/content-blocks/book";
import Donate from "@/router/content-blocks/donate";
import About from "@/router/content-blocks/about";

export default {
  components: {
    Billboard,
    Book,
    Donate,
    About,
  },
  data() {
    return {
      FullPageOptions: {
        licenseKey: "D15099DF-46D54288-BB85F984-BBE30BD6",
        dragAndMoveKey: 'ZXJpY2ZvbmRlbi5zZV8xZjFaSEpoWjBGdVpFMXZkbVU9NDRF',
        sectionSelector: "section",
        // anchors: ["home", "book", "donate", "about"],
        dragAndMove: 'fingersonly',
      },
    };
  },
  methods: {

  }
};
</script>

<style lang="scss">
.home-page {
  height: 100%;
  section {
      .fp-tableCell{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 1.25rem 0 1.25rem;
      }
      &.billboard {
        background-color: var(--brand-color-1);
      }
      &.book {
        background-color: var(--brand-color-2);
      }
      &.donate {
        background-color: var(--brand-color-1);
      }
      &.about {
        background-color: var(--primary-color);
      }
  }
  h1,h2 {
    font-family: $font-heading;
    font-size: 2.2rem;
    margin-bottom: 1.75rem;
    font-weight: 600;
  }
  p {
    font-size: min(1.6rem, 1rem + .8*1vw);
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 1.5rem;
    @include sm {
        font-size: 1.1rem;
    }
    &.intro {
      font-family: $font-heading;
      font-size: min(1.2rem + 2*1vw, 1.9rem);
      font-weight: 700;
      line-height: 1.15;
    }
    &.signature {
      background-image: url("~@/assets/signature.svg");
      display: flex;
      background-size: 125px;
      height: 70px;
      background-repeat: no-repeat;
      align-items: flex-end;
    }
  }
  .logo-wrapper {
    width: 200px;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .small {
    font-size: .8rem;
    line-height: 1.2;
  }  
}
</style>