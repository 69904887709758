<template>
  <div class="donate--content constrain-content--smaller">
    <h2>Vill du vara med och bidra?</h2>
    <p>
      <strong>Varje gåva gör skillnad, oavsett storlek.</strong> Pengarna
      hjälper oss att stötta barn med intellektuella funktionshinder.
    </p>
    <p>
      <strong
        >Ditt valfria belopp sätts in via
        <a @click.prevent="CopyToClipboard('0708448805')" class="highlight" content="Swishnumret är kopierat" v-tippy="TippyOptions">Swish 0708-448805</a
        ></strong
      >
      eller på konto 1336-0716744 (Danske Bank).
    </p>
    <p>
      <em class="small"
        >* Rune Engström står som mottagare för betalningen.</em
      >
    </p>
    <img src="@/assets/eric-och-kattis-1.jpg" alt="Eric och Katarina" />
  </div>
</template>
<script>
import TooltipMixin from '@/mixins/tooltip'
export default {
  mixins: [TooltipMixin],
  data() {
    return {
      
    }
  },
};
</script>

<style lang="scss">
.donate--content {
  img {
    border-radius: 100%;
    max-width: 150px;
  }
}
</style>
