<template>
  <div class="about--content constrain-content--smaller text-color--invert">
    <h2>Om Eric Torells minnesfond</h2>
    <p>Fonden har upprättats av Katarina Söderberg (mamma till Eric Torell) samt Rune Engström (författare till boken "25 skott").</p>
    <p>Fondens avsikt är att premiera och stötta personer och organisationer vars idéer skapar trygghet och glädje i vardagen för personer, som likt Eric, lever med en intellektuell funktionsnedsättning.</p>
    <p class="small">Fondens juridiska person håller just nu på att bildas och beräknas vara klart den 1 december. Tillsvidare är Rune Engström mottagare för gåvor via Swish- och kontoöverföring.</p>
    <div class="logo-wrapper">
      <logo :inverted="true" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Logo: () => import("@/components/ericfonden-logo"),
  }
};
</script>

<style lang="scss">
.about--content {
    .logo-wrapper {
        margin-top: 5rem;
        max-width: 150px;
    }
}
</style>
