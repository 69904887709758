<template>
  <div class="book--content constrain-content--small text-color--invert">
    <div class="book-cover">
      <picture>
        <source srcset="@/assets/25-skott-vertical.jpg" media="(min-width: 576px)">
        <img src="@/assets/25-skott-horisontal.jpg" />
      </picture>
    </div>
    <div class="book-description">
      <h2>Boken '25 skott' — dödsskjutningen av Eric Torell och polisens ansvar</h2>
      <p>
        I augusti 2018 sköts 20-åriga Eric Torell med Downs syndrom ihjäl av
        polisen när han var ute med sitt leksaksvapen i centrala Stockholm.
        Sammanlagt lossade poliserna 25 skott. Drygt ett år senare friades
        poliserna från alla brottsmisstankar.
      </p>
      <p class="extra">
        Journalisten Gabriel Cardona Cervantes och polisen Rune Engström
        granskar händelsen som berörde hela Sverige. De kartlägger de många
        taktiska misstag som polisen gjorde före skottlossningen och lägger ihop
        det ansvarspussel som aldrig färdigställdes efter Erics död.
      </p>
      <p>
        <a href="https://www.adlibris.com/se/bok/25-skott-dodsskjutningen-av-eric-torell-och-polisens-ansvar-9789177751564" class="highlight">
            <span class="mask">
                <div class="highlight-container">
                <span class="highlight-title1 title">Förbeställ boken på Adlibris.se</span>
                <span class="highlight-title2 title">Förbeställ boken på Adlibris.se</span>
                </div>
            </span>
        </a>
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss">
.book--content {
  .book-cover {
    margin: 0 auto 2rem auto;

    img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 8px;
    }
  }    
  @include max-sm {
    .book-description {
      .extra {
        display: none;
        @media (min-height: 736px) {
            display: block;
        }
      }
    }
  }

  
  @include sm {
      display: flex;
      grid-gap: 1.8rem;
      .book-cover {
        max-width: none;
        flex: 0 0 225px;
        margin: 0;
      }
      .book-description {
        flex: 1;
      }
  }
}
</style>
