export default {
    data() {
        return {
          TippyOptions: {
            trigger: 'click',
            arrow: true,
            size: 'large'
          },
        }
      },
      methods: {
        CopyToClipboard(text) {
          navigator.clipboard.writeText(text);
        },
      }
}
